.our_prosection .com_hd,.our_prosection .com_desc{color:#fff;}
.our_prosection{background:url(../../Helper/images/new/our_prosectionbg.png) no-repeat;width:100%;background-size: 100% 100%;}
.our_prosection .common_headingcontent{text-align: center;}
.productsinner{display:flex;flex-wrap:wrap;justify-content:center;gap:20px;padding:40px 0 0}
.product-card{background-color:#fff;border-radius:10px;box-shadow:0 4px 8px rgba(0,0,0,.1);width:calc(25% - 20px);padding:20px;text-align:left;display: flex;flex-direction: column;gap: 15px;position: relative;}
.product-card:before{width:100%;left:0;top:0;content:"";position: absolute;background:#FFF;height: 100%; z-index: -1;}
.product-card:hover{z-index: 3;}
.product-card:hover:before{width:103%;height:103%;background: #FFF;content:"";left:0;top:0;position: absolute;transition: all 0.4s ease;border-radius:5px;}
.product-card .pr_listhd{font-size: 19px;font-weight: 600;line-height: 25px;color:rgba(0, 0, 0, 1)}
.product-card .pr_listcontent{color:#777;font-size: 14px;line-height: 26px;}
.product-card a{display:flex;margin-top:10px;color:#174388;text-decoration:none;font-weight:500;font-size: 16px;gap: 5px;align-items: baseline;}.product-card a:hover{text-decoration:underline}
.pr_icoouter{width:82px;height:82px;background: linear-gradient(180deg, #00BBE4 0%, #077B95 100%);display: flex;border-radius: 100%;align-items: center;justify-content: center;}
.pr_icoouter2{background: linear-gradient(180deg, #FF326F 0%, #991E43 100%);}
.pr_icoouter3{background: linear-gradient(180deg, #FFA800 0%, #A87106 100%);}
.pr_icoouter4{background: linear-gradient(180deg, #00CA9A 0%, #038566 100%);}
@media only screen and (max-width:1024px) {
    .productsinner{padding:30px 0 0}
}
@media only screen and (max-width:1024px) {
    .product-card{width: calc(50% - 20px);}
}
@media only screen and (max-width:568px) {
    .product-card{width:calc(100% - 20px)}
}