.cards_wedo{background: url(../../Helper/images/new/what-we-do-bg.png) no-repeat;width:100%;height:100%;background-size: 100% 100%;}
.cards_wedo .common_headingcontent{text-align: center;}
.wedo_card{background-color:#fff;border-radius:40px;box-shadow:0 4px 8px rgba(0,0,0,.1);width:100%;max-width:240px;padding:20px;box-sizing:border-box;text-align:center;margin-top: 65px;position: relative;transition: all 0.4s ease;}
.wedo_card:nth-child(1){background-color:#FD646F;}
.wedo_card:nth-child(2){background-color:#B561C1;}
.wedo_card:nth-child(3){background-color:#00D3B0;}
.wedo_card:nth-child(4){background-color:#FFC345;}
.wedo_card:nth-child(5){background-color:#00B4ED;}
.wedo_cardtop{width:130px;height:130px;border:11px solid #fff;background:#FD646F;border-radius: 100%;position: absolute;margin-top: -90px;left: 50%;margin-left: -65px;display: flex;align-items: center;justify-content: center;}
.wedo_card:nth-child(1) .wedo_cardtop{background:#FD646F;}
.wedo_card:nth-child(2) .wedo_cardtop{background:#B561C1;}
.wedo_card:nth-child(3) .wedo_cardtop{background:#00D3B0;}
.wedo_card:nth-child(4) .wedo_cardtop{background:#FFC345;}
.wedo_card:nth-child(5) .wedo_cardtop{background:#00B4ED;}
.wedo_card:hover{z-index: 3;}
.wedo_card:before{width:100%;height:100%;content:"";left:0;top:0;position: absolute;transition: all 0.4s ease;border-radius:30px;transition: background 0.3s ease, width 0.2s ease, height 0.2s ease;}
.wedo_card:hover:before{width:102%;height:102%;}
.wedo_card:nth-child(1):hover:before{background: #FD646F;}
.wedo_card:nth-child(2):hover:before{background:#B561C1;}
.wedo_card:nth-child(3):hover:before{background:#00D3B0;}
.wedo_card:nth-child(4):hover:before{background:#FFC345;}
.wedo_card:nth-child(5):hover:before{background:#00B4ED;}

.wedo_card p{font-weight: 500;font-size: 15px;color:#FFFFFF;line-height: 25px;padding-top:60px;position: relative;}
@media only screen and (max-width:1024px) {
    .cards_outer{margin-top:20px}

}
@media only screen and (max-width:991px) {
    .cards_outer{justify-content: center; gap: 15px;}
    .wedo_card {width: 30%;}
}
@media only screen and (max-width:600px) {
    .wedo_card {width: 45%;}
}
@media only screen and (max-width:568px) {
.wedo_card {width: 100%; max-width: 100%;min-height: 200px;}
}
@media only screen and (max-width:480px) {
    .wedo_card p {
        font-size: 14px;
        margin-bottom: 10px;
    }
}