.textcenter {text-align: center;}
.common_section{padding:60px 0 80px }
.who_aresection .common_headingcontent{display: flex;gap:15px;flex-direction: column;align-items: center;}
.com_hd {font-size: 24px; font-weight: 700; line-height: 34px;color:rgba(34, 34, 34, 1)}
.com_desc{font-size: 16px; font-weight: 400; line-height: 29px;color:rgba(119, 119, 119, 1)}
.who_aresection {background: rgba(23, 67, 136, 0.03);}
.who_innersection { display: flex; flex-wrap: wrap;padding:60px 0 0 0}
.flex-item-left {flex: 40%;gap:30px;display: flex;flex-direction: column;}.flex-item-right {width: 60%;display: flex;justify-content: center;gap: 10px;position: relative;}
.who_innersection .flex-container{flex-direction: row;}
.whybox {display: flex;gap:20px;max-width: 100%;width: 100%;}
.whyboxicon {flex: 0 0 100px;display: flex;align-items: center;justify-content: center;background: rgba(23, 67, 136, 1);border-radius: 100%;width:100px;height:100px
}
.whyboxcontent{display: flex;flex-direction: column;gap:10px}
.why_bxhead{font-size: 19px;font-weight: 600;color:rgba(23, 67, 136, 1)}
.why_bxdesc{font-size: 15px;font-weight: 400;line-height: 27px;color:rgba(119, 119, 119, 1)}
.flexbx1 {display: flex;flex-direction: column;gap: 10px;z-index: 2;}
.secondrowflexbox1{margin-top:30px;}
.flexbx1 img{max-width:100%;object-fit: cover;border-radius: 5px;}
.fl_animationlft{width:107px; height:107px;border:2px dashed rgba(23, 67, 136, 1);border-radius: 100%;position: absolute;top:50%;left:0;margin-top:-65px;z-index:1;
    animation-name: pulse;animation-duration: 1.5s;animation-timing-function: ease-out;animation-iteration-count: infinite;transition: .5s;
}
@keyframes pulse {0% {box-shadow: 0 0 0 0 rgba(23, 67, 136, 1);}80% {box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);}}
.fl_animationlft:before{width:76px; height:76px;background:rgba(23, 67, 136, 1);border-radius: 100%;position: absolute;content: "";left:15px;top:15px}
.fl_animationrgt{left:auto;right:0;top:0;margin:-16px}

@media only screen and (max-width:1024px) {
.who_innersection{padding-top: 30px;}
    .flex-item-left{flex:100%}
    .flex-item-right{width:100%;margin-top:30px}
    .fl_animationlft{display: none;}
}
@media only screen and (max-width:480px) {
    .common_headingcontent {gap: 10px;}
    .com_hd {font-size: 20px; text-align: center;line-height: 30px;}
    .com_desc {font-size: 14px;}
    .whybox {flex-wrap: wrap;justify-content: center;}
    .whyboxcontent {text-align: center;}
    .flexbx1 img{height:auto}
    .whyboxicon{width:70px;height:70px;flex: 0 0 70px;}
    .whyboxicon img{width:40px;height:40px}
    
}